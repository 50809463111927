import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CarruselData } from 'src/models/input.model';

@Injectable({
    providedIn: 'root'
})
export class CarruselService {
    constructor(private http: HttpClient) {}

    /* Me permite obtener del json la ruta de las imágenes a mostrar en el carrusel */
    obtenerCarrusel(): Observable<CarruselData> {
        return this.http.get<CarruselData>('assets/json/carrusel.json');
    }
}
