export interface CarruselData {
    data: CarruselModel[];
}

export interface CarruselModel {
    src: string;
    title: string;
    alt: string;
}

export class DatosFormContacto {
    nombre: string;
    email: string;
    numMovil: string;
    tipoTrabajo: string;
    observaciones: string;

    constructor() {
        this.nombre = '';
        this.email = '';
        this.numMovil = '';
        this.tipoTrabajo = '';
        this.observaciones = '';
    }
}

export interface EmailTemplateModel {
    nombreRemitente: string;
    emailRemitente: string;
    numMovilRemitente: string;
    tipoTrabajo: string;
    observaciones: string;
}