<div class="container-fluid p-0">
    <app-navbar></app-navbar>

    <div class="page">
        <div class="wrapper">
            <div class="content">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>

    <div class="container-boton">
        <a href="https://wa.me/603219485?text=Hola!%20Deseo%20obtener%20un%20presupuesto!" target="_blank">
            <img class="boton" src="assets/images/iconos/icono.png" alt="Icono Whatsapp" />
        </a>
    </div>

    <app-footer></app-footer>
</div>
