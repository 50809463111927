<!-- Sección sobre nosotros -->
<section class="sobre-nosotros layout_padding">
    <div class="container">
        <div class="custom_heading-container">
            <h2 class=" ">Sobre nosotros</h2>
        </div>
        <p class="layout_padding2-top" [innerHTML]="articleText"></p>
    </div>
</section>
<!-- Fin de sección sobre nosotros -->
