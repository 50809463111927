import { Component } from '@angular/core';
import { SeccionAboutUs } from 'src/models/constants.model';

@Component({
  selector: 'app-sobre-nosotros',
  templateUrl: './sobre-nosotros.component.html',
  styleUrls: ['./sobre-nosotros.component.scss']
})
export class SobreNosotrosComponent {
    public articleText: string = SeccionAboutUs.articleText;

}
