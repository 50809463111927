import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

// PRIMENG IMPORTS
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GalleryModule } from 'ng-gallery';
import { ButtonModule } from 'primeng/button';
import { CarouselModule } from 'primeng/carousel';
import { InputTextModule } from 'primeng/inputtext';
import { MenubarModule } from 'primeng/menubar';
import { SidebarModule } from 'primeng/sidebar';
import { FooterComponent } from './footer/footer.component';
import { FormBaseComponent } from './form-base.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ErrorPageComponent } from './error-page/error-page.component';

@NgModule({
    declarations: [FormBaseComponent, NavbarComponent, FooterComponent, ErrorPageComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, HttpClientModule, MenubarModule, SidebarModule, CarouselModule, ButtonModule, GalleryModule, InputTextModule],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MenubarModule,
        SidebarModule,
        CarouselModule,
        ButtonModule,
        NavbarComponent,
        FooterComponent,
        GalleryModule,
        InputTextModule
    ]
})
export class SharedModule {}
