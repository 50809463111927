<!-- info section -->

<section class="info_section layout_padding">
    <div class="container">
        <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-3">
                <div class="info-logo">
                    <h5>Vaciados y Reformas JorMol</h5>
                    <p style="font-size: 18px">PROBAR ES ACERTAR!</p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="info-nav">
                    <h5>Navegación</h5>
                    <ul>
                        <li>
                            <a href="https://vaciadosyreformasjormol.es"> Inicio </a>
                        </li>
                        <li>
                            <a [routerLink]="['/sobre-nosotros']"> Sobre nosotros </a>
                        </li>
                        <li>
                            <a [routerLink]="['/nuestros-servicios']"> Nuestros servicios </a>
                        </li>
                        <li>
                            <a [routerLink]="['/contacto']"> Contacto </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-3">
                <div class="info-contact">
                    <h5>Información de contacto</h5>
                    <div class="location">
                        <h6>Ubicación de nuestra oficina:</h6>
                        <span>
                            <img loading="lazy" src="../../assets/images/iconos/location.avif" alt="Icono location" title="Icono location"/>
                            <span> Roda de Bará, 43883 </span>
                        </span>
                    </div>
                    <div class="call">
                        <h6>Teléfonos de contacto:</h6>
                        <span>
                            <img loading="lazy" src="../../assets/images/iconos/telephone.avif" alt="Icono telefono" title="Icono telefono"/>
                            <span> Jesús ( 603219485 ) </span>
                        </span>
                        <span>
                            <img loading="lazy" src="../../assets/images/iconos/telephone.avif" alt="Icono telefono" title="Icono telefono"/>
                            <span> Carlos ( 635437668 ) </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- end info_section -->

<!-- footer section -->
<section class="container-fluid footer_section">
    <p>Copyright &copy; 2023 | Vaciados y Reformas JorMol | Todos los Derechos Reservados</p>
</section>
<!-- footer section -->
