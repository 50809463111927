import { Component, OnInit } from '@angular/core';
import { GalleryItem, ImageItem } from 'ng-gallery';
import { CarruselData } from 'src/models/input.model';
import { CarruselService } from 'src/services/carrusel.service';

@Component({
    selector: 'app-carrusel',
    templateUrl: './carrusel.component.html',
    styleUrls: ['./carrusel.component.scss']
})
export class CarruselComponent implements OnInit {
    images: GalleryItem[] = [];

    constructor(private carruselService: CarruselService) {}

    ngOnInit(): void {
        /* Recupera del servicio las imágenes del carrusel */
        this.carruselService.obtenerCarrusel().subscribe({
            next: (datosServicio: CarruselData) => {
                datosServicio['data'].forEach((carruselData) => {
                    this.images = [...this.images, new ImageItem({ src: carruselData.src, alt: carruselData.alt })];
                });
            },
            error: (e: any) => {
                console.error(e);
            }
        });
    }
}
