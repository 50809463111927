import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    /* Propiedades del navbar */
    showNavbarButtons: boolean = true;
    lastElementActive: string = '';
    toggleFlag: boolean = false;
    showCollapsedNavbar: boolean = false;

    ngOnInit(): void {
        this.checkSize();

        /* Evento que escucha cuando cambiamos el tamaño de la pantalla para mostrar u ocultar el toggle colapsando el navbar */
        window.addEventListener('resize', () => {
            if (window.innerWidth > 1060) {
                this.showCollapsedNavbar = false;
                this.showNavbarButtons = true;

                if (this.lastElementActive !== '' && this.toggleFlag === true) {
                    setTimeout(() => {
                        this.switchActiveClass(this.lastElementActive);
                    }, 50);
                    this.toggleFlag = false;
                }
            } else {
                this.showNavbarButtons = false;
                this.toggleFlag = true;
            }
        });
    }

    // PUBLIC METHODS

    /* Cuando clico en cualquiera de los botones del navbar activo el seleccionado y desactivo los otros */
    switchActiveClass(elementoSeleccionado?: string) {
        /* Obtengo los elemento div */
        let navButtonAboutUs: Element | null = document.querySelector('.navButtonAboutUs');
        let navButtonServices: Element | null = document.querySelector('.navButtonServices');
        let navButtonContact: Element | null = document.querySelector('.navButtonContact');
        let toggleButton: Element | null = document.querySelector('.toggleButton');

        switch (elementoSeleccionado) {
            case 'navButtonAboutUs':
                navButtonAboutUs?.classList.toggle('active');
                navButtonServices?.classList.remove('active');
                navButtonContact?.classList.remove('active');
                toggleButton?.classList.remove('active');

                this.lastElementActive = elementoSeleccionado;
                this.showCollapsedNavbar = false;
                scrollTo(0,0);
                break;
            case 'navButtonServices':
                navButtonAboutUs?.classList.remove('active');
                navButtonServices?.classList.toggle('active');
                navButtonContact?.classList.remove('active');
                toggleButton?.classList.remove('active');

                this.lastElementActive = elementoSeleccionado;
                this.showCollapsedNavbar = false;
                scrollTo(0,0);
                break;
            case 'navButtonContact':
                navButtonAboutUs?.classList.remove('active');
                navButtonServices?.classList.remove('active');
                navButtonContact?.classList.toggle('active');
                toggleButton?.classList.remove('active');

                this.lastElementActive = elementoSeleccionado;
                this.showCollapsedNavbar = false;
                scrollTo(0,0);
                break;
            default:
                navButtonAboutUs?.classList.remove('active');
                navButtonServices?.classList.remove('active');
                navButtonContact?.classList.remove('active');
                toggleButton?.classList.remove('active');
                scrollTo(0,0);
                break;
        }
    }

    /* Permite activar o desactivar el toggle button y mostrar los botones colapsados en forma de lista */
    switchToggleButton() {
        let toggleButton: Element | null = document.querySelector('.toggleButton');

        toggleButton?.classList.toggle('active');

        this.showCollapsedNavbar = this.showCollapsedNavbar === true ? false : true;
    }

    // PRIVATE METHODS

    /* Verifica el tamaño de la pantalla para mostrar u ocultar los botones del navbar y mostrar el toggle */
    private checkSize() {
        if (window.innerWidth > 1060) {
            this.showCollapsedNavbar = false;
            this.showNavbarButtons = true;
        } else {
            this.showNavbarButtons = false;
        }
    }
}
