import { NgModule } from '@angular/core';
import { CarruselService } from 'src/services/carrusel.service';
import { SharedModule } from 'src/shared/shared.module';
import { CarruselComponent } from './carrusel/carrusel.component';
import { HomeComponent } from './home/home.component';
import { SobreNosotrosComponent } from './sobre-nosotros/sobre-nosotros.component';
import { NuestrosServiciosComponent } from './nuestros-servicios/nuestros-servicios.component';
import { ContactoComponent } from './contacto/contacto.component';
import { NuestroTrabajoComponent } from './nuestro-trabajo/nuestro-trabajo.component';
import { PoliticaPrivacidadComponent } from './politica-privacidad/politica-privacidad.component';
import { PoliticaCookiesComponent } from './politica-cookies/politica-cookies.component';

@NgModule({
    declarations: [HomeComponent, CarruselComponent, SobreNosotrosComponent, NuestrosServiciosComponent, ContactoComponent, NuestroTrabajoComponent, PoliticaPrivacidadComponent, PoliticaCookiesComponent],
    imports: [SharedModule],
    providers: [CarruselService]
})
export class ComponentsModule {}
