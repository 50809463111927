import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactoComponent } from 'src/components/contacto/contacto.component';
import { HomeComponent } from 'src/components/home/home.component';
import { NuestrosServiciosComponent } from 'src/components/nuestros-servicios/nuestros-servicios.component';
import { PoliticaCookiesComponent } from 'src/components/politica-cookies/politica-cookies.component';
import { PoliticaPrivacidadComponent } from 'src/components/politica-privacidad/politica-privacidad.component';
import { SobreNosotrosComponent } from 'src/components/sobre-nosotros/sobre-nosotros.component';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent
    },
    {
        path: 'sobre-nosotros',
        component: SobreNosotrosComponent
    },
    {
        path: 'nuestros-servicios',
        component: NuestrosServiciosComponent
    },
    {
        path: 'contacto',
        component: ContactoComponent
    },
    {
        path: 'politica-privacidad',
        component: PoliticaPrivacidadComponent
    },
    {
        path: 'politica-cookies',
        component: PoliticaCookiesComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
