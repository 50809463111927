import { Component } from '@angular/core';

@Component({
  selector: 'app-nuestro-trabajo',
  templateUrl: './nuestro-trabajo.component.html',
  styleUrls: ['./nuestro-trabajo.component.scss']
})
export class NuestroTrabajoComponent {

}
