import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as emailjs from '@emailjs/browser';
import { Observable } from 'rxjs';
import { DatosFormContacto } from 'src/models/input.model';

@Injectable({
    providedIn: 'root'
})
export class ContactoService {
    constructor(private http: HttpClient) {
        emailjs.init('m26fbHBBt7FYzqnt6');
    }

    /* Me permite enviar un email con los datos del formulario */
    enviarEmail(formData: DatosFormContacto): Observable<any> {
        let url: string = 'https://api.emailjs.com/api/v1.0/email/send';
        let data: any = {
            service_id: 'service_vjm_2023',
            template_id: 'template_z3thmg9',
            user_id: 'm26fbHBBt7FYzqnt6',
            template_params: {
                nombre: formData.nombre,
                email: formData.email,
                numMovil: formData.numMovil,
                tipoTrabajo: formData.tipoTrabajo,
                observaciones: formData.observaciones
            }
        };

        let headers: HttpHeaders = new HttpHeaders('Content-Type: application/json');

        return this.http.post<any>(url, data, { headers: headers });
    }
}
