<!-- Sección nuestro trabajo -->
<section class="nuestro-trabajo layout_padding">
    <div class="container">
        <div class="custom_heading-container">
            <h2 class=" ">Nuestro trabajo</h2>
        </div>
    </div>
    <div class="work_container">
        <div class="box b-1">
            <div class="img-box">
                <img loading="lazy" src="../../assets/images/trabajos/recogidaEnseres.avif" alt="Imagen recogida" />
            </div>
            <div class="name">
                <p>Vaciados y recogida de muebles</p>
            </div>
        </div>
        <div class="box b-2">
            <div class="img-box">
                <img loading="lazy" src="../../assets/images/trabajos/w-2.avif" alt="Imagen trabajo 1" />
            </div>
            <div class="name">
                <p>Reformas de todo tipo</p>
            </div>
        </div>
        <div class="box b-3">
            <div class="img-box">
                <img loading="lazy" src="../../assets/images/trabajos/w-3.avif" alt="Imagen trabajo 2" />
            </div>
            <div class="name">
                <p>Presupuesto ajustado</p>
            </div>
        </div>
        <div class="box b-4">
            <div class="img-box">
                <img loading="lazy" src="../../assets/images/trabajos/w-4.avif" alt="Imagen trabajo 3" />
            </div>
            <div class="name">
                <p>Climatización</p>
            </div>
        </div>
        <div class="box b-5">
            <div class="img-box">
                <img loading="lazy" src="../../assets/images/trabajos/s-1.avif" alt="Imagen trabajo 4" />
            </div>
            <div class="name">
                <p>Fontanería</p>
            </div>
        </div>
        <div class="box b-6">
            <div class="img-box">
                <img loading="lazy" src="../../assets/images/trabajos/electricidad.avif" alt="Imagen electricidad" />
            </div>
            <div class="name">
                <p>Electricidad</p>
            </div>
        </div>
        <div class="box b-7">
            <div class="img-box">
                <img loading="lazy" src="../../assets/images/trabajos/w-1.avif" alt="Imaagen trabajo 5" />
            </div>
            <div class="name">
                <p>Trabajos manuales</p>
            </div>
        </div>
    </div>
</section>
<!-- Fin sección nuestro trabajo -->
