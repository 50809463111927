<!-- Empieza el header -->
<header class="header_section">
    <p-menubar>
        <!-- Título del navbar -->
        <ng-template pTemplate="start">
            <a class="navbar-title" href="https://vaciadosyreformasjormol.es" (click)="switchActiveClass()">Vaciados y Reformas JorMol</a>
        </ng-template>

        <!-- Botones del navbar -->
        <ng-template pTemplate="end">
            <ng-container *ngIf="showNavbarButtons; else showToggle">
                <div class="navbarButtons d-flex mx-auto flex-column flex-lg-row align-items-center">
                    <ul class="navbar-nav">
                        <li class="nav-item navButtonAboutUs" (click)="switchActiveClass('navButtonAboutUs')">
                            <a class="nav-link" [routerLink]="['/sobre-nosotros']">Sobre nosotros</a>
                        </li>
                        <li class="nav-item navButtonServices" (click)="switchActiveClass('navButtonServices')">
                            <a class="nav-link" [routerLink]="['/nuestros-servicios']">Nuestros servicios</a>
                        </li>
                        <li class="nav-item navButtonContact" (click)="switchActiveClass('navButtonContact')">
                            <a class="nav-link" [routerLink]="['/contacto']">Contacto</a>
                        </li>
                    </ul>
                </div>
            </ng-container>

            <!-- Template que muestra el toggle cuando la pantalla está por debajo de 1060px -->
            <ng-template #showToggle>
                <div class="toggleButton" (click)="switchToggleButton()">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </ng-template>

            <!-- Template que se muestra cuando la pantalla está por debajo de 1060px al clicar en el toggle button -->
            <ng-container *ngIf="showCollapsedNavbar && !showNavbarButtons">
                <div class="navbarButtonsCollapsed d-flex mx-auto flex-column flex-lg-column align-items-center">
                    <ul class="navbar-nav">
                        <li class="nav-item navButtonAboutUs" (click)="switchActiveClass('navButtonAboutUs')">
                            <a class="nav-link" [routerLink]="['/sobre-nosotros']">Sobre nosotros</a>
                        </li>
                        <li class="nav-item navButtonServices" (click)="switchActiveClass('navButtonServices')">
                            <a class="nav-link" [routerLink]="['/nuestros-servicios']">Nuestros servicios</a>
                        </li>
                        <li class="nav-item navButtonContact" (click)="switchActiveClass('navButtonContact')">
                            <a class="nav-link" [routerLink]="['/contacto']">Contacto</a>
                        </li>
                    </ul>
                </div>
            </ng-container>
        </ng-template>
    </p-menubar>
</header>
<!-- Termina el header -->
