<!-- Sección contacto -->
<section class="contacto layout_padding">
    <div class="custom_heading-container">
        <h2 class=" ">Contacta con nosotros</h2>
    </div>
    <div class="container layout_padding2-top">

        <!-- Formulario de contacto -->
        <form #formContacto="ngForm">
            <div class="row">
                <div class="col-md-6 mx-auto">
                    <!-- Campo Nombre -->
                    <div class="col-lg-12 mt-3">
                        <span class="p-float-label">
                            <input type="text" pInputText [id]="'txtNombre'" autocomplete="off" [(ngModel)]="datosFormContacto.nombre" [name]="'txtNombre'" required="true" />
                            <label class="input-label" [for]="'txtNombre'">Nombre</label>
                            <span class="validity"></span>
                        </span>
                    </div>

                    <!-- Campo Correo electrónico -->
                    <div class="col-lg-12 mt-3">
                        <span class="p-float-label">
                            <input
                                type="email"
                                pInputText
                                [id]="'txtEmail'"
                                autocomplete="off"
                                [(ngModel)]="datosFormContacto.email"
                                (ngModelChange)="validaPatternFormulario('txtEmail')"
                                [name]="'txtEmail'"
                                required="true"
                                pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
                            />
                            <label class="input-label" [for]="'txtEmail'">Correo electrónico</label>
                            <span class="validity"></span>
                        </span>
                        <span *ngIf="showEmailError" class="input-error">Debe introducir un correo electrónico válido</span>
                    </div>

                    <!-- Campo Número de móvil -->
                    <div class="col-lg-12 mt-3">
                        <span class="p-float-label">
                            <input
                                type="tel"
                                pInputText
                                [id]="'txtMovil'"
                                autocomplete="off"
                                [(ngModel)]="datosFormContacto.numMovil"
                                (ngModelChange)="validaPatternFormulario('txtMovil')"
                                required="true"
                                pattern="^[0-9]*$|[0-9]{3} [0-9]{2} [0-9]{2} [0-9]{2}"
                                minlength="9"
                                maxlength="14"
                                [name]="'txtMovil'"
                                required="true"
                            />
                            <label class="input-label" [for]="'txtMovil'">Número de móvil</label>
                            <span class="validity"></span>
                        </span>
                        <span *ngIf="showNumberFormatError" class="input-error">Debe introducir un número de móvil válido</span>
                    </div>

                    <!-- Campo Tipo de trabajo -->
                    <div class="col-lg-12 mt-3">
                        <span class="p-float-label">
                            <select aria-label="Trabajo a realizar" pInputText style="color: black;" [name]="'ddTrabajoRealizar'" [id]="'ddTrabajoRealizar'" pattern="[A-Z]{1}" [(ngModel)]="ddSelectedValue" required="true">
                                <option label="Selecciona el trabajo a realizar*" value="" disabled hidden selected>Selecciona el trabajo a realizar*</option>
                                <option label="Vaciado o recogida" value="V">{{DDTRABAJOREALIZAR['0']['valor']}}</option>
                                <option label="Reformas, lampistería, pintura, etc." value="R">{{DDTRABAJOREALIZAR['1']['valor']}}</option>
                            </select>
                            <span class="validity"></span>
                        </span>
                    </div>

                    <!-- Campo Observaciones -->
                    <div class="col-lg-12 mt-3">
                        <span class="p-float-label">
                            <textarea
                                type="text"
                                pInputText
                                [id]="'txtObservaciones'"
                                autocomplete="off"
                                [(ngModel)]="observaciones"
                                [name]="'txtObservaciones'"
                                required="false"
                                rows="5"
                                maxlength="1000"
                            ></textarea>
                            <label [for]="'txtObservaciones'">Observaciones</label>
                        </span>
                    </div>

                    <!-- Botón enviar -->
                    <div class="d-flex justify-content-center">
                        <button type="submit" (click)="enviarFormulario()">ENVIAR</button>
                    </div>

                    <div class="col-lg-11 mt-3">
                        <p><em>*Los datos del formulario no quedan registrados, sólo se usan para el envío del presupuesto con copia al solicitante del mismo.</em></p>
                    </div>
                </div>
            </div>
        </form>
        <!-- Termina el formulario de contacto -->
    </div>
</section>
<!-- Fin de la sección contacto -->
