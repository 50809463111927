<!-- Sección nuestros servicios -->
<section class="nuestros-servicios layout_padding">
    <div class="container">
        <div class="custom_heading-container">
            <h2 class=" ">NUESTROS SERVICIOS</h2>
        </div>
        <div class="service_container">
            <div class="row">
                <div class="col-lg-12">
                    <h2><strong>Vaciado de pisos en Tarragona y Barcelona</strong></h2>
                    <p class="description">
                        Realizamos todo tipo de vaciados en Tarragona y Barcelona! Para su comodidad usted no tiene que ensuciarse las manos, tan solo debe llamarnos y nosotros mismos seremos quienes
                        procedamos a vaciar el lugar que necesite.
                    </p>
                </div>
                <div class="col-lg-12 pt-5">
                    <h2><strong>Recogida de muebles en Tarragona y Barcelona</strong></h2>
                    <p class="description">
                        Recogemos muebles en Tarragona y Barcelona. Destacar que tiene un coste dicha recogida, salvo que de dicha recogida podamos obtener alguna
                        rentabilidad, salimos ganando nosotros y ustedes que se han desecho de lo que querían sin coste alguno y con facilidad.
                    </p>
                </div>
                <div class="col-lg-12 pt-5">
                    <h2><strong>Reformas en Tarragona y Barcelona</strong></h2>
                    <p class="description">
                        Nuestro equipo puede realizar cualquier tipo de reforma en Tarragona y Barcelona. Es cuestión de contactarnos, hablarlo, realizarle un presupuesto y manos a la obra!
                    </p>
                </div>
                <div class="col-lg-12 pt-5">
                    <h2><strong>Trabajos de limpieza en Tarragona y Barcelona</strong></h2>
                    <p class="description">
                        Realizamos trabajos de limpieza del hogar, nave, parcela, recinto etc. Disponemos de productos profesionales para dicha limpieza.
                    </p>
                </div>
                <div class="col-lg-12 pt-5">
                    <h2><strong>Trabajos de pintura en Tarragona y Barcelona</strong></h2>
                    <p class="description">
                        Sea lo que sea que necesite pintar, nuestro equipo de profesionales estará encantado de realizar esa labor por usted. Nuestros acabados siempre son de
                        la mejor calidad, no lo dude.
                    </p>
                </div>
                <div class="col-lg-12 pt-5">
                    <h2><strong>Trabajos de lampistería en Tarragona y Barcelona</strong></h2>
                    <p class="description">
                        Para cualquier trabajo de lampistería que pueda necesitar en Tarragona, ahi estaremos, tan solo ha de llamarnos y nuestros profesionales realizarán esas tareas que
                        necesite.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Fin de sección nuestros servicios -->
