import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from '../shared/shared.module';
import { ComponentsModule } from 'src/components/components.module';
import { GALLERY_CONFIG, GalleryConfig } from 'ng-gallery';

@NgModule({
    declarations: [AppComponent],
    imports: [BrowserModule, BrowserAnimationsModule, AppRoutingModule, SharedModule, ComponentsModule],
    providers: [
        {
            provide: GALLERY_CONFIG,
            useValue: {
                autoHeight: false,
                imageSize: 'contain',
                thumb: false,
                autoPlay: true,
                dots: false,
                counter: false,
                nav: false,
                loadingStrategy: 'lazy',
            } as GalleryConfig
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
