import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SeccionContacto } from 'src/models/constants.model';
import { DatosFormContacto } from 'src/models/input.model';
import { ContactoService } from 'src/services/contacto.service';
import { FormBaseComponent } from 'src/shared/form-base.component';

@Component({
    selector: 'app-contacto',
    templateUrl: './contacto.component.html',
    styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent extends FormBaseComponent {
    @ViewChild('formContacto', { static: false }) public formContacto!: NgForm;

    /* Propiedades constantes */
    public DDTRABAJOREALIZAR = Object.freeze(SeccionContacto.ddTrabajoRealizar);

    /* Propiedades del formulario de contacto */
    datosFormContacto: DatosFormContacto = new DatosFormContacto();
    showNumberFormatError: boolean = false;
    showEmailError: boolean = false;
    ddSelectedValue: string = '';
    observaciones: string = '';

    constructor(private contactoService: ContactoService) {
        super();
    }

    /* Permite enviar un correo electrónico con los datos del formulario */
    enviarFormulario() {
        if (this.formContacto.invalid) {
            this.formContacto.controls['txtNombre'].valid ? this.clearControlError('txtNombre') : this.setControlError('txtNombre');
            this.formContacto.controls['txtEmail'].valid ? this.clearControlError('txtEmail') : this.setControlError('txtEmail');
            this.formContacto.controls['txtMovil'].valid ? this.clearControlError('txtMovil') : this.setControlError('txtMovil');
            this.formContacto.controls['ddTrabajoRealizar'].valid ? this.clearControlError('ddTrabajoRealizar') : this.setControlError('ddTrabajoRealizar');
        } else {
            /* Recupero la clave seleccionada del desplegable para asignarle su valor */
            if (this.formContacto.controls['ddTrabajoRealizar'].value === this.DDTRABAJOREALIZAR['0'].llave) {
                this.datosFormContacto.tipoTrabajo = this.DDTRABAJOREALIZAR['0']['valor'];
            } else if (this.formContacto.controls['ddTrabajoRealizar'].value === this.DDTRABAJOREALIZAR['1'].llave) {
                this.datosFormContacto.tipoTrabajo = this.DDTRABAJOREALIZAR['1']['valor'];
            }

            /* Si no ha introducido nada en el campo observaciones, le meto mensaje por defecto */
            this.datosFormContacto.observaciones = this.observaciones === '' ? 'Sin observaciones' : this.observaciones;

            /* Realizo el envío del mail y recupero el resultado del envío para mostrar el alert y resetear el formulario */
            this.contactoService.enviarEmail(this.datosFormContacto).subscribe({
                next: (data: any) => {
                    console.log(data.json());
                },
                error: (e: any) => {
                    if (e.status === 200) {
                        alert('Solicitud procesada correctamente');
                        this.formContacto.resetForm();
                        this.formContacto.controls['ddTrabajoRealizar'].setValue('');
                    } else {
                        alert('Error al procesar la solicitud, intentelo de nuevo más tarde');
                    }
                    
                }
            });
        }
    }

    /* Valida si el formato de los campos es correcto o no para mostrar mensaje de error */
    validaPatternFormulario(campo: string) {
        switch (campo) {
            case 'txtEmail':
                if (this.formContacto.controls['txtEmail'].valid || this.formContacto.controls['txtEmail'].value === '') {
                    this.showEmailError = false;
                } else {
                    this.showEmailError = true;
                }
                break;
            case 'txtMovil':
                if (this.formContacto.controls['txtMovil'].valid || this.formContacto.controls['txtMovil'].value === '') {
                    this.showNumberFormatError = false;
                } else {
                    this.showNumberFormatError = true;
                }
                break;
            default:
                break;
        }
    }
}
