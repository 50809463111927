import { Directive, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import _isEqual from 'lodash/fp/isEqual';
import { Obj } from '../core/utils/object.utils';

@Directive({
    selector: 'FormDirectiveComponent'
})
export class FormBaseComponent {
    @ViewChild(NgForm, { static: true }) public form!: NgForm;

    protected reference: any;

    constructor() {}

    public resetForm() {
        if (this.form) {
            this.form.reset();
        }
    }

    public markFormAsDirty() {
        if (this.form) {
            // tslint:disable-next-line:forin
            for (let inner in this.form.controls) {
                this.form.form.get(inner)?.markAsDirty();
            }
        }
    }

    public setControlError(controlName: string) {
        if (this.form) {
            let control = this.form.controls[controlName];
            if (control) {
                control.markAsDirty();
                control.setErrors({ incorrect: true });
            }
        }
    }

    public clearControlError(controlName: string) {
        if (this.form) {
            let control = this.form.controls[controlName];
            if (control) {
                control.setErrors(null);
            }
        }
    }

    protected createReference(obj: any) {
        // this.reference = Object.assign({}, obj);
        this.reference = Obj.clone(obj);
    }

    protected isPropertyDifferent(obj: any, prop: string) {
        return this.reference[prop] !== obj[prop];
    }

    protected hasChanges(newObj: any): boolean {
        let changed = false;
        changed = !_isEqual(this.reference, newObj);
        return changed;
    }
}
