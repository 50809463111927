import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';

export abstract class Obj {
    public static clone(target: any): any {
        if (!target) {
            return null;
        }
        return _cloneDeep(target);
    }

    public static notNull(target: any, props: any = null): boolean {
        if (target === null || typeof target === 'undefined') {
            return false;
        }

        if (props) {
            const value: any = _get(target, props);
            return value !== null && typeof value !== 'undefined';
        }
        return true;
    }

    public static isEmpty(target: any): boolean {
        return target === null || typeof target === 'undefined' || Object.entries(target).length === 0;
    }

    public static isNumber(x: any): x is number {
        return typeof x === 'number';
    }

    public static isString(x: any): x is string {
        return typeof x === 'string';
    }

    public static compare(arr1: any, arr2: any): boolean {
        let result = false;
        if (!arr1 || !arr2) {
            return result;
        }
        arr1.forEach((e1: any) =>
            arr2.forEach((e2: any) => {
                if (e1.length > 1 && e2.length) {
                    result = Obj.compare(e1, e2);
                } else if (e1 !== e2) {
                    result = false;
                } else {
                    result = true;
                }
            })
        );
        return result;
    }

    public static objCompare(obj1: any, obj2: any): boolean {
        const Obj1_keys = Object.keys(obj1);
        const Obj2_keys = Object.keys(obj2);

        for (let k of Obj1_keys) {
            if ((obj1[k] !== null || (undefined && obj2[k] !== null) || undefined) && k === Obj2_keys.find((v) => v === k)) {
                if (obj1[k] !== obj2[k]) {
                    return false;
                }
            }
        }
        return true;
    }
}
