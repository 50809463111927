import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { SeccionAboutUs } from 'src/models/constants.model';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    articleText: string = Object.freeze(SeccionAboutUs.articleText);

    ngOnInit(): void {}

    centrarSeccion(seccion: string): void {
        switch (seccion) {
            case 'header':
                scrollTo(0, 0);
                break;
            case 'sobreNosotros':
                /* scrollTo(0, 1300); */
                document.querySelector('#sobreNosotros')?.scrollIntoView({ behavior: 'smooth' });
                break;

            case 'servicios':
                /* scrollTo(0, 2470); */
                document.querySelector('#servicios')?.scrollIntoView({ behavior: 'smooth' });
                break;

            case 'nuestrosClientes':
                /* scrollTo(0, 3740); */
                document.querySelector('#nuestrosClientes')?.scrollIntoView({ behavior: 'smooth' });
                break;

            case 'contacto':
                /* scrollTo(0, 5010); */
                document.querySelector('#contacto')?.scrollIntoView({ behavior: 'smooth' });
                break;

            default:
                break;
        }
    }
}
