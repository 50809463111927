<div class="main-container">
    <div class="title-container">
        <h1 class="main-page-title">Vaciado de pisos en Tarragona y Barcelona - Probar es acertar!</h1>
    </div>

    <!-- Sección carrusel -->
    <app-carrusel></app-carrusel>
    <!-- Termina sección carrusel -->

    <!-- Sección sobre nosotros -->
    <app-sobre-nosotros></app-sobre-nosotros>
    <!-- Termina sección sobre nosotros -->

    <!-- Sección nuestros servicios -->
    <app-nuestros-servicios></app-nuestros-servicios>
    <!-- Termina sección nuestros servicios -->

    <!-- Sección nuestro trabajo -->
    <app-nuestro-trabajo></app-nuestro-trabajo>
    <!-- Fin sección nuestro trabajo -->

    <!-- Sección contacto -->
    <app-contacto></app-contacto>
    <!-- Termina sección contacto -->
</div>
